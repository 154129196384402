/*
 *
 */

export default class SearchBox {

    constructor($view) {
        this.$view = $view;
        this.lastStringSearch = [];
        this.subfilter = { logic: 'or', filters: [] };

        // Problème la grille n'est pas forcément up à ce moment là
        //this.GridId = $('[data-role="grid"]', this.$view).first().attr('id');

        this.Init();
    }



    Init() {
        let self = this;
        let radio = this.$view.find('.radioSearch');

        radio.toArray().forEach(function (item) {
            self.radioSearch(item);
        });

        // Si vous souhaitez une des méthodes de searchbox. Pensez a la bind ici (comme pour radioSearch juste au dessus) :

    }

    externalFilters() {
        var data = {};
        $.each($('.externalSearch'), function (i, item) {
            data[$(item).data('search')] = $(item).val();
        });
        return data;
    }

    externalStringSearch(input) {
        var searchInput = $(input);
        searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            var item = this;
            if (checkMinimum(item.value) && item.value != lastStringSearch[searchInput.id]) {
                delay(function () {
                    lastStringSearch[searchInput.id] = item.value;
                    callRead();
                }, 700);
            }
        });
        searchInput.bind('paste', function () {
            if (checkMinimum(this.value))
                callRead();
        });
    }

    stringSearch(input) {
        var searchInput = $(input);
        searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            var item = this;
            if (checkMinimum(item.value) && item.value != lastStringSearch[searchInput.id]) {
                delay(function () {
                    lastStringSearch[searchInput.id] = item.value;
                    applyStringFilter(item);
                }, 700);
            }
        });
        searchInput.bind('paste', function () {
            if (checkMinimum(this.value))
                applyStringFilter(this);
        });
    }

    numericSearch(input) {
        var searchInput = $(this);
        searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.change(function () {
            applyNumericFilter(this);
        });
    }

    dateSearch(input, filterType) {
        var searchInput = $(input);
        searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.keyup(function () {
            if (this.value == '' || isDate(this.value))
                applyDateFilter(this, filterType);
        });
    }

    checkSearch(input) {
        var searchInput = $(input);
        searchInput.focus();
        searchInput.addClass('searchi');
        searchInput.click(function () {
            applyCheckFilter(this);
        });
    }

    radioSearch(input) {
        let self = this;
        var searchInput = $(input);
        searchInput.addClass('searchi');
        var isNotBoolean = false;

        if (searchInput.data('isnotboolean') === true) {
            isNotBoolean  = true;
        }

        searchInput.click(function () {
            self.applyRadioFilter(this, isNotBoolean);
        });
    }

    selectSearch() {
        $(this).change(function () {
            applyDropDownListFilter(this);
        });
    }

    callRead() {
        // $('[data-role="grid"]', this.$view).first() => permet de récupérer la première grille du scope du composant
        var gridData = $('[data-role="grid"]', this.$view).first().data('kendoGrid');
        gridData.dataSource.read();
    }

    getDefaultValues() {
        //var filter = null;
        $.each($('[default-criteria]'), function (i, value) {
            var item = $(value);

            if (item.hasClass('externalSearch'))
                item.keyup();
            else if (item.hasClass('stringSearch'))
                item.keyup();
            else if (item.hasClass('numericSearch'))
                item.keyup();
            else if (item.hasClass('dateSearch'))
                item.keyup();
            else if (item.hasClass('checkSearch'))
                item.click();
            else if (item.hasClass('selectSearch'))
                item.change();
        });
        //if (filter != null) {
        //    callRead();
        //}
    }

    applyFilter(filterField, filterValue, filterOperator, logic) {//, isDeferredRequest){
        // $('[data-role="grid"]', this.$view).first() => permet de récupérer la première grille du scope du composant
        var gridData = $('[data-role="grid"]', this.$view).first().data('kendoGrid');
        var currFilterObj = gridData.dataSource.filter();
        var currentFilters;
        currentFilters = currFilterObj ? currFilterObj.filters : [];
        if (currentFilters && currentFilters.length > 0) {
            for (var i = 0; i < currentFilters.length; i++) {
                if (currentFilters[i].field == filterField && currentFilters[i].operator == filterOperator) {
                    currentFilters.splice(i, 1);
                    break;
                }
            }
        }
        i++;

        var format = '';
        if (filterField.indexOf('date') > 1)
            format = '{0:dd/MM/yyyy}';

        if (filterValue != 'NONE')
            currentFilters.push({ field: filterField, operator: filterOperator, value: filterValue });

        //if (isDeferredRequest)
        //    return { logic: logic, filters: currentFilters };
        //else
        gridData.dataSource.filter({ logic: logic, filters: currentFilters });
    }

    toggleSubFilter(filterField) {
        let self = this;

        var gridData = $('[data-role="grid"]', this.$view).first().data('kendoGrid');
        //Il se peut qu'on ne soit pas sur une grid mais sur un treeList
        if (gridData == null) {
            gridData = $('[data-role="treelist"]', this.$view).first().data('kendoTreeList');
        }

        var currFilterObj = gridData.dataSource.filter();
        var currentFilters;
        currentFilters = currFilterObj ? currFilterObj.filters : [];
        if (self.subfilter && self.subfilter.filters.length > 0 && currentFilters.some(obj => obj.field === filterField) == false) {

            var qs = self.subfilter.filters[0];

            currentFilters.push(self.subfilter.filters[0]);
        }
        else {
            $.each(currentFilters, function (index, item) {
                if (item !== undefined) {
                    if (item.field == filterField) {
                        currentFilters.splice(index, 1);
                    }
                }
            });
            // Replace
            if (self.subfilter && self.subfilter.filters.length > 0) {
                currentFilters.push(self.subfilter.filters[0]);
            }
        }
        gridData.dataSource.filter({ logic: 'and', filters: currentFilters });
    }

    applyStringFilter(item) {
        var searchCriteria = $(item).data('search');
        var q = $(item).val();
        if (q != '')
            applyFilter(searchCriteria, q, 'contains', 'and');
        else
            applyFilter(searchCriteria, 'NONE', 'contains', 'and');
    }

    applyNumericFilter(item) {
        var searchCriteria = $(item).data('search');
        var q = $(item).val();
        if (q != '')
            applyFilter(searchCriteria, q, 'eq', 'and');
        else
            applyFilter(searchCriteria, 'NONE', 'eq', 'and');
    }

    applyDateFilter(item, filterType) {
        var searchCriteria = $(item).data('search');
        var q = $(item).val();
        if (q != '')
            if (filterType == "lte")
                applyFilter(searchCriteria, formatDate(q) + " 11:59:59 PM", filterType, 'and');
            else if (filterType == "gte")
                applyFilter(searchCriteria, formatDate(q) + " 00:00:00 AM", filterType, 'and');
            else
                applyFilter(searchCriteria, formatDate(q), filterType, 'and');
        else
            applyFilter(searchCriteria, 'NONE', filterType, 'and');
    }

    applyCheckFilter(item, filterType) {
        let self = this;
        var searchCriteria = $(item).data('search');
        var values = $(item).val().split('|');
        if ($(item).is(':checked')) {
            $.each(values, function (index, value) {
                self.subfilter.filters.push({ field: searchCriteria, operator: 'eq', value: value });
            });
        }
        else {
            $.each(values, function (index, value) {
                for (var i = 0; i < self.subfilter.filters.length; i++) {
                    if (self.subfilter.filters[i].value == value) {
                        self.subfilter.filters.splice(i, 1);
                        break;
                    }
                }
            });
        }
        self.toggleSubFilter(searchCriteria);
    }

    applyRadioFilter(item, typeNotBoolean) {

        let self = this;
        var searchCriteria = $(item).data('search');
        var value = $(item).val();

        // On efface tous les filtres pour ce critere
        for (var i = 0; i < self.subfilter.filters.length; i++) {
            if (self.subfilter.filters[i].field === searchCriteria) {
                self.subfilter.filters.splice(i, 1);
                break;
            }
        }

        if ($(item).hasClass("removeAllOtherFilters")) {
            for (var i = 0; i < self.subfilter.filters.length; i++) {
                self.subfilter.filters.splice(i, 1);
            }
        }

        if ($(item).is(':checked') && !$(item).hasClass("removeFilter")) {

            if (!typeNotBoolean) {
                // dansle cas d'une valaur à mettre en Boolean
                let val = (value === 'true'); // on convertit en booleen
                self.subfilter.filters.push({ field: searchCriteria, operator: 'eq', value: val });
            } else {
                //Dans le cas d'une valeur string ou int ou autre on passe la value 
                self.subfilter.filters.push({ field: searchCriteria, operator: 'eq', value: value });
            }
        }

        self.toggleSubFilter(searchCriteria);
    }

    applyDropDownListFilter(item) {
        var searchCriteria = $(item).data('search');
        var q = $(item).val();
        if (q != '')
            applyFilter(searchCriteria, q, 'eq', 'and');
        else
            applyFilter(searchCriteria, 'NONE', 'eq', 'and');
    }

    isDate(value) {
        var date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
        return date_regex.test(value);
    }

    formatDate(value) {
        var dateItem = value.split('/');
        return dateItem[1] + '/' + dateItem[0] + '/' + dateItem[2];
    }

    checkMinimum(value) {
        return (value.length == 0 || value.length >= 2);
    }
}