require('bedrock');

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import '@progress/kendo-ui'
import '@progress/kendo-ui/js/cultures/kendo.culture.fr.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js';

import App from '6tm-components';
window.App = App;

//import 'bootstrap';
import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
//import Globalize from '6tm-globalize';
//import '6tm-globalize/src/cultures/fr-FR';

//window.Globalize = Globalize;

//import 'Respond.js/dest/respond.src'; // Uselfull ?

import toastr from 'toastr';
window.toastr = toastr;

import Swal from "sweetalert2/dist/sweetalert2";
window.Swal = Swal;

// Components
import AppComponent from './js/components/AppComponent';
import alert from './js/components/alert';
import alertDeletion from './js/components/alertDeletion';
import clientAdresse from './js/components/clientAdresse';
import clientCatalogue from './js/components/clientCatalogue';
import clientTarif from './js/components/clientTarif';
import fileUploadEditor from './js/components/FileUploadEditor';
import fileBrowserUploadEditor from './js/components/fileBrowserUploadEditor';
import grillesTarifaires from './js/components/grillesTarifaires';
import grilleTarifaireAcheteur from './js/components/grilleTarifaireAcheteur';
import grilleTarifaireAcheteurTreeList from './js/components/grilleTarifaireAcheteurTreeList';
import grilleTarifaireDistributeur from './js/components/grilleTarifaireDistributeur';
import navigationOnglets from './js/components/navigationOnglets';
import orderableGrid from './js/components/orderableGrid';
import produitsImport from './js/components/produitsImport';
import produitsSurcharge from './js/components/produitsSurcharge';
import searchBox from './js/components/searchBox';
import swalPopup from './js/components/swalPopup';
import unimplemented from './js/components/unimplemented';
import gridComponent from './js/components/GridComponent';
import clientDistributeurDragAndDrop from './js/components/clientDistributeurDragAndDrop';
import clientGrilleTarifaireDragAndDrop from './js/components/clientGrilleTarifaireDragAndDrop';
import droitsDistributeur from './js/components/droitsDistributeur';
import catalogue from './js/components/catalogue';
import produitFichiers from './js/components/ProduitFichiers'

// Pages
import ImportList from './js/pages/import-list';
import pageCatalogue from './js/pages/PageCatalogue';
import pageExportCatalogue from './js/pages/pageExportCatalogue';

App.Kernel.registerComponent('AppComponent', AppComponent)
App.Kernel.registerComponent('Alert', alert)
App.Kernel.registerComponent('AlertDeletion', alertDeletion)
App.Kernel.registerComponent('ClientAdresse', clientAdresse)
App.Kernel.registerComponent('ClientCatalogue', clientCatalogue)
App.Kernel.registerComponent('ClientTarif', clientTarif)
App.Kernel.registerComponent('FileUploadEditor', fileUploadEditor)
App.Kernel.registerComponent('FileBrowserUploadEditor', fileBrowserUploadEditor)
App.Kernel.registerComponent('GrillesTarifaires', grillesTarifaires)
App.Kernel.registerComponent('GrilleTarifaireAcheteur', grilleTarifaireAcheteur)
App.Kernel.registerComponent('grilleTarifaireAcheteurTreeList', grilleTarifaireAcheteurTreeList)
App.Kernel.registerComponent('GrilleTarifaireDistributeur', grilleTarifaireDistributeur)
App.Kernel.registerComponent('NavigationOnglets', navigationOnglets)
App.Kernel.registerComponent('OrderableGrid', orderableGrid)
App.Kernel.registerComponent('ProduitsImport', produitsImport)
App.Kernel.registerComponent('ProduitsSurcharge', produitsSurcharge)
App.Kernel.registerComponent('SearchBox', searchBox)
App.Kernel.registerComponent('SwalPopup', swalPopup)
App.Kernel.registerComponent('Unimplemented', unimplemented)
App.Kernel.registerComponent('ImportList', ImportList)
App.Kernel.registerComponent('GridComponent', gridComponent)
App.Kernel.registerComponent('PageCatalogue', pageCatalogue)
App.Kernel.registerComponent('PageExportCatalogue', pageExportCatalogue)
App.Kernel.registerComponent('ClientDistributeurDragAndDrop', clientDistributeurDragAndDrop)
App.Kernel.registerComponent('ClientGrilleTarifaireDragAndDrop', clientGrilleTarifaireDragAndDrop)
App.Kernel.registerComponent('DroitsDistributeur', droitsDistributeur)
App.Kernel.registerComponent('Catalogue', catalogue)
App.Kernel.registerComponent('ProduitFichiers', produitFichiers)

$(() => {
    App.Kernel.bindComponents($("body"));
});

const OrderableGrid = require('./js/OrderableGrid');
window.OrderableGrid = new OrderableGrid();

const AppError = require('./js/AppError');
window.AppError = new AppError();

import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.editor';
import '@progress/kendo-ui/js/kendo.filtercell';
import '@progress/kendo-ui/js/kendo.aspnetmvc';
import '@progress/kendo-ui/js/kendo.treelist';



import './scss/main.scss';