/*
 * Class permettant d'afficher le nom du fichier upload dans l'input
 */

export default class FileBrowserUploadEditor {

    constructor($view) {
        this.view = $view;
        this.BaseUrl = this.view.data("base-url");
        this.SrcImage = "";
        this.Init();
    }

    Init() {
        var thisObj = this;

        var settings = {
            root: thisObj.BaseUrl,
            readAction: "Read",
            destroyAction: "Destroy",
            createAction: "Create",
            thumbnailUrlAction: "Thumbnail",
            uploadUrlAction: "Upload",
            fileTypes: "*.png,*.gif,*.jpg,*.jpeg,*.svg"
        };

        var read = settings.root + settings.readAction;
        var destroy = settings.root + settings.destroyAction;
        var create = settings.root + settings.createAction;
        var thumbnailUrl = settings.root + settings.thumbnailUrlAction;
        var uploadUrl = settings.root + settings.uploadUrlAction;

        $("textarea", thisObj.view).kendoEditor({
            tools: [
                "insertImage"
            ],
            imageBrowser: {
                fileTypes: "*.png,*.gif,*.jpg,*.jpeg,*.svg",
                messages: {
                    dropFilesHere: "Drop files here"
                },
                transport: {
                    type: "imagebrowser-aspnetmvc",
                    read: read,
                    destroy: {
                        url: destroy,
                        type: "POST"
                    },
                    create: {
                        url: create,
                        type: "POST"
                    },
                    thumbnailUrl: thumbnailUrl,
                    uploadUrl: uploadUrl,
                    imageUrl: settings.root + "Image?path={0}"
                },
            },
            select: function (e) {
                //e.preventDefault();
                var img = $(this.value()).attr('src');
                var alt = $(this.value()).attr('alt');
                var rgxp = /\/Image\?path=(.*)$/i;
                var match = rgxp.exec(img);
                var tbValue = decodeURIComponent(match[1]);

                $(".image-input", thisObj.view).val(tbValue).change();
                $("textarea", thisObj.view).data("kendoEditor").value("");
                $(".thumbnail img", thisObj.view).attr("src", settings.root + "Image?path=" + tbValue);
                $(".thumbnail img", thisObj.view).removeClass("hidden");
            }
        });

        // Pour simuler le click 
        $(".image-picker-link", thisObj.view).click(function () {
            $("textarea", thisObj.view).closest('table.k-widget.k-editor').find(".k-icon.k-i-image").trigger('click');
        });

        // afin de supprimer la source de l'image
        $(".image-picker-delete", thisObj.view).click(function () {
            $(".image-input", thisObj.view).val("");
            $(".image-overview", thisObj.view).attr('src', '');
        });

        //$(".image-overview", thisObj.view).click(function () {
        //    // On récupère la source de l'image
        //    var src = $(".image-overview", thisObj.view).attr('src');

        //    // On affiche la modale que si y'a une source'
        //    if (src && src !== "") {

        //        var modal = window.Modal.LoadModal("editorialWindowModal", null, null, "Image", "auto", "65%", null, "<img src='" + src + "'>");
        //    }
        //});
    }
}

