/*
 * Permet la navigation d'onglets dans le détail d'un element
 * exemple : détails d'un produits ou edition d'un client
 */
import UrlManager from '../components/url-manager';

export default class NavigationOnglets {

    constructor($view) {

        this.$view = $view;
        this.Init();
    }

    Init() {
        let self = this;

        // On va set la vue active au load de la page
        self.setActiveView(self);

        this.$view.on("click", '.nav-link', (e) => {
            self.changeOnglets(self, e.currentTarget.id);
            self.activeOrNotMenu(self, e.currentTarget.id);
        });
    }

    // Change l'onglet et affiche la bonne vue
    // Pour ce fonctionnement il faut avoir un element nav avec un id et une div qui contient une partiale avec le même id + 'View'
    changeOnglets(self, id) {
        let partialViews = self.$view.find(".partialView");

        for (var i = 0; i < partialViews.length; i++) {
            $(partialViews[i]).hide();
        }

        self.$view.find('#' + id + "View").show();

        // on va mettre l'onglet actif dans l'url
        var urlParams = {};
        urlParams.ongletActif = id;
        UrlManager.UpdateCurrentUrl(null, urlParams);
    }

    // Désactive tous le smenus et active le menu voulu
    activeOrNotMenu(self, id) {
        let nav = self.$view.find(".nav-link");

        for (var i = 0; i < nav.length; i++) {
            $(nav[i]).removeClass("active");
        }

        self.$view.find('#' + id).addClass("active");
    }

    // Masque toutes les partial et active la partial voulue
    activeOrNotView(self, id) {
        let partialViews = self.$view.find(".partialView");

        for (var i = 0; i < partialViews.length; i++) {
            $(partialViews[i]).hide();
        }

        self.$view.find('#' + id + 'View').show();
    }

    // Au load de la page pour afficher le bon onglet si le param est présent dans l'url
    setActiveView(self) {
        
        var ongletActif = UrlManager.GetUrlParameter("ongletActif");

        // on check si y'a le param onglet dans l'url
        if (ongletActif) {

            self.activeOrNotMenu(self, ongletActif);
            self.activeOrNotView(self, ongletActif);

        } else {
            // sinon on fait rien
        }
    }
}