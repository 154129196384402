import Swal from "sweetalert2/dist/sweetalert2";
import toastr from 'toastr';

export default class AppComponent {
    constructor($view) {
        this.view = $view;
        console.log('AppComponent');
        this.SuccessToaster = this.view.data("success-toaster");
        this.FailToaster = this.view.data("fail-toaster");

        this.SuccessToasterMessage = this.view.data("success-toaster-message");
        this.FailToasterMessage = this.view.data("fail-toaster-message");


        this.CurrentController = this.view.data("current-controller");
        this.CurrentAction = this.view.data("current-action");
        this.CurrentArea = this.view.data("current-area");
        this.Init();
    }

    Init() {
        var self = this;

        // Gestion des toast
        if (self.SuccessToaster === 'True') {

            // Pour afficher un message dans un toast depuis le c#
            // TempData["SuccessToaster"] = true;
            // TempData["SuccessToasterMessage"] = "Mon message";

            // on test la nullité ou la chaine vide
            if (!!self.SuccessToasterMessage) {
                toastr.success(self.SuccessToasterMessage);
            } else {
                toastr.success("Opération effectuée avec succès");
            }
        }

        if (self.FailToaster === 'True') {

            // Pour afficher un message dans un toast depuis le c#
            // TempData["FailToaster"] = true;
            // TempData["FailToasterMessage"] = "Mon message";

            // on test la nullité ou la chaine vide
            if (!!self.FailToasterMessage) {
                toastr.error(self.FailToasterMessage);
            } else {
                toastr.error("Une erreur est survenue");
            }
        }

        // Afin de enligh le bon menu
        if (self.CurrentController !== '') {
            //On laisse ouvert le menu quand on clique sur un sous menu
            var element = $(".app-aside-body #" + self.CurrentController + "Menu");
            var parent = element.closest('.has-children');
            parent.addClass("opened");

            $(".app-aside-body a").removeClass('active');
            if (self.CurrentAction == "Historique") {
                $(".app-aside-body #" + self.CurrentController + "HistoriqueMenu").addClass('active')
            }
            else {
                $(".app-aside-body #" + self.CurrentController + "Menu").addClass('active')
            }
        }


        //Gestion des boutons de confirmations
        $('.confirmAction', self.view).on("click", function (e) {

            // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
            e.preventDefault(); //ne fonctionne pas :/
            // On récupère les data apposées sur le boutons
            let data = e.currentTarget.dataset;

            let type = "POST";
            if (data.type === "GET" || data.type === "POST") {
                type = data.type;
            }

            Swal.fire({
                title: data.titlemodal,
                text: data.textemodal,
                icon: data.iconmodal,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then((result) => {

                console.log('la');

                if (result.isConfirmed) {
                    $.ajax({
                        type: type,
                        url: data.url,

                        success: function (data) {

                            if (data.resultOk == 1) {
                                if (data.parameterUrl) {
                                    window.location.href = data.parameterUrl;
                                } else {
                                    toastr.success("Opération effectuée avec succès");
                                }
                            } else {
                                toastr.options = {
                                    timeOut: 0,
                                    extendedTimeOut: 0
                                };
                                toastr.error("Une erreur est survenue");
                            }
                        },
                        error: function (retour) {
                            toastr.error("Une erreur est survenue");
                        }
                    });
                }
            })
            
        });
    }
}