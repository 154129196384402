export default class ImageUploadComponent {

    constructor($view) {
        this.view = $view;
        this.Bind();
    }

    Bind() {
        let self = this;

        console.log('sqdf');

        $(".delete-image", self.view).on("click", function () {
            self.deleteImage();
        })

        $(".image-input", self.view).change(function () {

            // On set le path dans le champs Hidden
            $(".hidden-path", self.view).val($(this).val());

            // On récupère le fileName
            //var fileName = $(this).val().split('\\').pop();
            //$(".file-name", self.view).text(fileName);
            //$(".image-overview img", self.view).attr("src", fileName);

            $(".image-overview img", self.view).attr("src", "/upload/none");



            self.removeValidationErrors();
        });

        $(".image-overview", self.view).click(function () {
            // On récupère la source de l'image
            var src = $(".image-overview img", self.view).attr('src');

            // On affiche la modale que si y'a une source'
            if (src && src !== "") {
                var modal = window.Modal.LoadModal("windowModal", null, null, "Image", "80%", "65%", null, "<img src='" + src + "'>");
                modal.center();
            }
        });

    }

    deleteImage() {
        let self = this;

        $(".hidden-path", self.view).val("");
        $(".image-input", self.view).val("");
        $(".file-name", self.view).text("");
        $(".image-overview img", self.view).attr("src", "/upload/none");

        self.removeValidationErrors();
    }

    removeValidationErrors() {
        let self = this;

        //on vide le field validation error et on retire les erreurs pour permettre à la validation de se faire à nouveau
        $('.field-validation-error', self.view).removeClass('field-validation-error').addClass('field-validation-valid').text('');
        $('.input-validation-error', self.view).removeClass('input-validation-error');
    }
}