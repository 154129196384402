import { data } from "jquery";
import Swal from "sweetalert2/dist/sweetalert2";
import toastr from 'toastr';

export default class GrilleTarifaireAcheteur {
    constructor($view) {
        this.$view = $view;
        this.SuccessOperationMsg = this.$view.data('success-msg');
        this.UpdateMsg = this.$view.data('update-msg');
        this.CreateMsg = this.$view.data('create-msg');
        this.DeleteMsg = this.$view.data('delete-msg');
        this.ConfirmDeleteTitle = this.$view.data('swal-title');
        this.GrilleId = this.$view.data('grilleid');
        this.ConfirmDeleteOverrideMsg = this.$view.data('swal-msg');
        this.UpdateCoefValueUrl = this.$view.data('updatecoefvalueurl');
        this.UpdateProduitViewUrl = this.$view.data('updateproduitviewurl');
        this.ResetProduitViewUrl = this.$view.data('resetproduitviewurl');
        this.RepercuteGtUrl = this.$view.data('repercutegturl');
        this.urlExportWin = this.$view.data('url-exportwin');

        this.Init();
    }

    Init() {
        let self = this;

        // On s'abonne au click du bouton de maj des coef de la grille
        $("#override-grilletarifaire").on("click", function () {
            self.OverrideGrilleTarifaire();
        });

        // On s'abonne au click du bouton de maj de répercution des grilles tarifaires enfants
        $("#repercute-grilletarifaire").on("click", function () {
            self.RepercuteGrilleTarifaire();
        });


        // On s'abonne au click du bouton de maj des coef des produit affichés
        $("#override-produits-view").on("click", function () {
            self.OverrideProduitsView();
        });

        // On s'abonne au click du bouton de reset des coef des produit affichés
        $("#reset-produits-view").on("click", function () {
            self.ResetProduitsView();
        });

        // Afin d'exposer sur la page les méthodes JS du composant (pour la grille Kendo)
        window.onDataBound = self.window_onDataBound;
        window.setVisible = self.window_setVisible;
        window.onRequestEnd = self.window_onRequestEnd;
        window.showDeleteConfirmation = self.window_showDeleteConfirmation;
        window.showDeleteConfirmation = self.window_showDeleteConfirmation;

        // afin d'utiliser les messages passés en ressource dans un contexte hors composant (sans le this)
        window.SuccessOperationMsg = this.SuccessOperationMsg
        window.UpdateMsg = this.UpdateMsg;
        window.CreateMsg = this.CreateMsg;
        window.DeleteMsg = this.DeleteMsg;
        window.ConfirmDeleteTitle = this.ConfirmDeleteTitle;
        window.ConfirmDeleteOverrideMsg = this.ConfirmDeleteOverrideMsg;

        this.$view.on("click", '.LaunchExport', (e) => {
            console.log('ici');
            self.launchExport(e.currentTarget.dataset)
        });
    }


    // Lance l'export direct
    launchExport(data) {
        let self = this;

        $.ajax({
            type: "GET",
            url: self.urlExportWin,
            data: {
                id: data.id,
                idClient: data.clientid
            },
            success: function (data) {

                var jobId = data.jobId;
                if (jobId) {

                    // il faut rediriger vers la liste des catalogue
                    window.open("/hangfire/jobs/details/" + jobId, '_blank').focus();
                }

                // il faut rediriger vers la liste des catalogue
                window.location.href = "/GrillesTarifairesAcheteur/ExportLog";

            }
        });

        toastr.success("L'export Win System à été lancé. Vous pouvez le retrouver dans l'onglet Log des exports Win System");
    }

    RepercuteGrilleTarifaire() {
        let self = this;

        Swal.fire({
            title: 'Répercuter sur les grilles des clients enfants ?',
            showCancelButton: true,
            confirmButtonText: 'Valider',
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
                return new Promise((resolve, reject) => {
                    // Afin de modifier le count des produits concerné sur la page
                    $.ajax({
                        url: self.RepercuteGtUrl,
                        data: {
                            id: self.GrilleId
                        },
                        type: 'POST',
                        success: function (data) {
                            // Résoudre la Promise avec le résultat de l'appel Ajax
                            resolve(data);
                        },
                        error: function (error) {
                            // En cas d'erreur, rejeter la Promise
                            reject(error);
                        }
                    });
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value > 0) {
                    // on affiche une confirmation via toastr
                    toastr.success("Répercution effectué avec succès, création de " + result.value + " grilleTarifaireProduits");
                } else {
                    // on affiche une info
                    toastr.info("Aucune répercution effectuée");
                }
            }
        })

        
    }

    // afin de modifier les valeur de coef par défaut de la grille tarifaire
    OverrideGrilleTarifaire() {
        let self = this;

        // on récupère les valeurs des coeff
        var coefVente = $("#OverridedCoefficientDeVenteParDefaut", self.$view).val();
        var coefAjustementAchat = $("#OverridedCoefficientAjustementAchatParDefaut", self.$view).val();

        // Afin de modifier le count des produits concerné sur la page
        $.ajax({
            url: self.UpdateCoefValueUrl,
            data: {
                coefVente,
                coefAjustementAchat
            },
            type: 'POST',
            success: function (data) {
                toastr.success(UpdateMsg + " " + SuccessOperationMsg);

                // il faut recharger la grille
                self.RefreshGrid();
            }
        });
    }

    // afin de reset les valeur de coef par défaut des produit affiché
    ResetProduitsView() {
        let self = this;

        var grid = $("#Grid_GrilleTarifaire").data("kendoGrid");
        var dataSource = grid.dataSource;

        // Récupérer les paramètres de la grille afin de filtrer le IQueryable derière
        var request = grid.dataSource.transport.parameterMap({
            filter: grid.dataSource.filter(),
            page: grid.dataSource.page(),
            pageSize: grid.dataSource.pageSize(),
            sort: grid.dataSource.sort(),
            group: grid.dataSource.group()
        })

        Swal.fire({
            title: "Reset les tarifs",
            text: "Êtes vous certain de vouloir les tarifs de l'ensemble des produits affichés ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: function () {

                // On va passer une partie des paramètre en GET
                const urlWithParams = `${self.ResetProduitViewUrl}?id=${self.GrilleId}`;

                return new Promise((resolve, reject) => {
                    // Afin de modifier le count des produits concerné sur la page
                    $.ajax({
                        url: urlWithParams,
                        // Seul DataSourceRequest est envoyé dans le body
                        data: request,
                        type: 'POST',
                        success: function (data) {
                            // Résoudre la Promise avec le résultat de l'appel Ajax
                            resolve(data);
                        },
                        error: function (error) {
                            // En cas d'erreur, rejeter la Promise
                            reject(error);
                        }
                    });
                });

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                // on affiche une confirmation via toastr
                toastr.success(UpdateMsg + " " + SuccessOperationMsg);
                // il faut recharger la grille
                self.RefreshGrid();
            }
        })
    }


    // afin de modifier les valeur de coef par défaut de la grille tarifaire
    OverrideProduitsView() {
        let self = this;

        var grid = $("#Grid_GrilleTarifaire").data("kendoGrid");
        var dataSource = grid.dataSource;

        // Récupérer les paramètres de la grille afin de filtrer le IQueryable derière
        var request = grid.dataSource.transport.parameterMap({
            filter: grid.dataSource.filter(),
            page: grid.dataSource.page(),
            pageSize: grid.dataSource.pageSize(),
            sort: grid.dataSource.sort(),
            group: grid.dataSource.group()
        })
        
        // Pour l'affichage du html dans la swal
        const htmlString = `
                <div style='float-left;text-align:left'>
                <label style="width:70px" class="form-check-label" for="exclu">exclu</label>
                <input class="form-check-input" id="exclu" name="exclu" type="checkbox">
                </div>
                </br>
                <label style="text-align:left;width:100%">coefficient de vente</label>
                <input type="number" class="form-control" placeholder="coefficient de vente" id="coeff_vente">
                </br>
                <label style="text-align:left;width:100%">coefficient d'ajustement d'achat</label>
                <input type="number" class="form-control" placeholder="coefficient d'ajustement d'achat" id="coeff_ajustementAchat">
        `;

        Swal.fire({
            title: 'Modifier les coéfficients des produits',
            html: htmlString,
            showCancelButton: true,
            confirmButtonText: 'Valider',
            showLoaderOnConfirm: true,
            preConfirm: (value) => {

                const coeff_vente = document.getElementById('coeff_vente').value;
                const coeff_ajustementAchat = document.getElementById('coeff_ajustementAchat').value;
                const excluCb = document.getElementById('exclu');

                var exclu = false;
                
                if (excluCb.checked) {
                    exclu = true;
                }

                const coeffVente = parseFloat(coeff_vente);
                const coeffAjustementAchat = parseFloat(coeff_ajustementAchat);

                // On va passer une partie des paramètre en GET
                const urlWithParams = `${self.UpdateProduitViewUrl}?cv=${coeffVente}&caa=${coeffAjustementAchat}&exclu=${exclu}&id=${self.GrilleId}`;

                return new Promise((resolve, reject) => {
                    // Afin de modifier le count des produits concerné sur la page
                    $.ajax({
                        url: urlWithParams,
                        // Seul DataSourceRequest est envoyé dans le body
                        data: request,
                        type: 'POST',
                        success: function (data) {
                            // Résoudre la Promise avec le résultat de l'appel Ajax
                            resolve(data);
                        },
                        error: function (error) {
                            // En cas d'erreur, rejeter la Promise
                            reject(error);
                        }
                    });
                });

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                // on affiche une confirmation via toastr
                toastr.success(UpdateMsg + " " + SuccessOperationMsg);
                // il faut recharger la grille
                self.RefreshGrid();
            }
        })
    }

    //Permet de refresh la grid
    RefreshGrid() {
        let self = this;

        var grid = $("#Grid_GrilleTarifaire").data("kendoGrid");
        grid.dataSource.read();
        grid.refresh();
    }

    // Sur l'event DataBound de la grille
    window_onDataBound() {
        //Afin de différer la coleur de la ligne en fonction de si il y a surcharge ou pas
        var grid = $("#Grid_GrilleTarifaire").data("kendoGrid");
        var data = grid.dataSource.data();
        $.each(data, function (i, row) {
            if (row.OverrideOrNotBool) {
                $('tbody tr[data-uid="' + row.uid + '"]').css("background-color", "#00000008");
            } else {
                $('tbody tr[data-uid="' + row.uid + '"]').css("background-color", "#fbfbfb");
            }
        })

        // Afin de modifier le count des produits concerné sur la page
        $.ajax({
            url: $("#grilleTarifaireAcheteur-component").data("setcountnbproduiturl"),
            type: 'POST',
            success: function (data) {
                $("#NbProduitConcerne").data("kendoNumericTextBox").value(data.NbProduitConcerne);
                $("#NbProduitSurcharge").data("kendoNumericTextBox").value(data.NbProduitSurcharge);
            }
        });
    }

    window_setVisible(dataItem) {
        // return true or false dependent on the dataItem field value
        return dataItem.OverrideOrNotBool;
    }

    // Afin de forcer le readf de la grille après une suppression ou un update
    window_onRequestEnd(e) {
        if (e.type === "destroy") {
            e.sender.read();
            toastr.success(DeleteMsg + " " + SuccessOperationMsg);
        } else if (e.type === "create") {
            e.sender.read();
            toastr.success(CreateMsg + " " + SuccessOperationMsg);
        }
        else if (e.type === "update") {
            e.sender.read();
            toastr.success(UpdateMsg + " " + SuccessOperationMsg);
        }
    }

    window_showDeleteConfirmation(e) {
        // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
        e.preventDefault();

        var grid = $("#Grid_GrilleTarifaire").data("kendoGrid");
        var modelToDelete = grid.dataItem($(e.target).parents('tr'));

        Swal.fire({
            title: ConfirmDeleteTitle,
            text: ConfirmDeleteOverrideMsg + " " + modelToDelete.IdentifiantExterne + " ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) => {
            if (result.isConfirmed) {
                grid.dataSource.remove(modelToDelete);
                grid.dataSource.sync();
            }
        })
    }
}