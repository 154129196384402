import Swal from "sweetalert2/dist/sweetalert2";
import toastr from 'toastr';

export default class ProduitFichiers {

    constructor($view) {
        console.log('ProduitFichiers');
        this.$view = $view;
        this.SetImagePrincipaleUrl = this.$view.data('setimageprincipaleurl');
        this.Init();
    }

    Init() {

        var self = this;

        // on s'abonne au click sur le bouton de set de l'image principale

        $(self.$view).on('click', '.js-set-imageprincipale', function (e) {
            
            Swal.fire({
                title: 'Définir comme image principale ?',
                text: 'Voulez vous définir cette image comme image principale ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non'
            }).then((result) => {
                if (result.isConfirmed) {

                    $.ajax({
                        url: self.SetImagePrincipaleUrl,
                        // Seul DataSourceRequest est envoyé dans le body
                        data: { urlImage: $(this).data('urlimage') },
                        type: 'POST',
                        success: function (data) {
                            window.location.reload();
                        },
                        error: function (error) {
                            toastr.error('Une erreur est survenue');
                        }
                    });
                }
            })
        });
    }
}